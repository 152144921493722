<template>
    <div class="section-subscribe wrap" style="margin-top: 4rem">
        <div class="subscribe-wrap">
            <form data-members-form="subscribe" data-members-autoredirect="false" class="subscribe-form">
                <h3>Suscríbete a nuestra newsletter</h3>
                <div id="ityped-subscribe" class="ityped">
                    <span class="ityped-wrap subscribe">No enviamos spam, lo prometemos!</span>
                </div>
                <div class="form-group">
                    <input data-members-email class="subscribe-input" placeholder="Tu dirección de email" type="email" name="email" aria-label="Tu dirección de email" required>
                    <label class="accept-privacy-policiy">
                        <input type="checkbox" id="accept-privacy-policiy" required> Acepto la <a href="/politica-privacidad" target="_blank">Política De Privacidad</a>
                    </label>
                </div>
                <button type="submit" class="global-button">Suscribirse</button>
            </form>
            <p class="subscribe-alert-loading">Procesando tu petición</p>
            <p class="subscribe-alert-error">Ha habido un error procesando tu petición, intentelo más tarde.</p>
            <div class="subscribe-success">
                <h3>Genial!</h3>
                Revisa tu bandeja de entrada para confirmar tu suscripción.
            </div>
        </div>
    </div>
    
</template>

<style>
	.accept-privacy-policiy {
		display: block;
		margin-bottom: 30px;
	}
</style>